<template>
  <div class="px-8 py-8 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent class="py-8" title="Stack Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="TB 700-2 Sympathetic Reaction (SR) Unconfined Stack Test Setup Example"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/setup-stack-test-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/setup-stack-test-min.jpg" />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing the stack test is to determine whether
              there is propagation from one package to another or from one
              unpackaged article to another from accidental ignition/
              initiation. If so it is a candidate for HD 1.1.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>
                      Product quantity (specified package volume and quantity)
                    </td>
                    <td>
                      Provide a sufficient quantity of product to enable
                      determination of the propagation potential
                    </td>
                    <td></td>
                    <td>
                      Sufficient packages or articles to give a total volume of
                      0.15 m^3. If the volume of an individual package (or
                      unpackaged article) exceeds 0.15 m^3 then at least one
                      acceptor is placed in a position to result in
                      communication between the individual products.
                    </td>
                  </tr>
                  <tr>
                    <td>Confining medium and quantity</td>
                    <td>
                      Provide a displaceable confining medium that resists the
                      venting of hot combustion gases
                    </td>
                    <td>
                      <small
                        >Simulate typical transport conditions (package
                        stacking)</small
                      >
                    </td>
                    <td>
                      The preferred method of confinement consists of
                      containers, similar in shape and size to the test package,
                      completely filled with earth or sand and placed as closely
                      as possible around the test package to a minimum thickness
                      of confinement, in every direction of 1 m. Alternative
                      methods of confinement are to use boxes or bags filled
                      with earth or sand placed around and on top of the package
                      or to use loose sand. If loose sand is used for
                      confinement, the stack should be covered or protected to
                      ensure that no sand falls into the interstices between
                      adjacent packages or non-packaged articles.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Ignition stimulus (detonator, igniter, or device function)
                    </td>
                    <td>
                      Ensure ignition/ initiation of the substance or article as
                      intended or designed (or equivalent)
                    </td>
                    <td>
                      <small
                        >Most likely incident to be encountered during
                        transport</small
                      >
                    </td>
                    <td>
                      The method of ignition depends on the how the device/
                      substance is intended to function as per guidelines in the
                      UN Manual of Tests and Criteria, Test 6 (b).
                    </td>
                  </tr>
                  <tr>
                    <td>Stimulus placement</td>
                    <td>Assess effect on surrounding materials</td>
                    <td>
                      <small>Assess effect on surrounding materials</small>
                    </td>
                    <td>
                      Ignition/ initiation in the center of the center most
                      package or article
                    </td>
                  </tr>
                  <tr>
                    <td>Witness plate</td>
                    <td>Evidence of reaction type</td>
                    <td></td>
                    <td>3.0 mm thick mild steel</td>
                  </tr>
                  <tr>
                    <td>Blast measurement equipment</td>
                    <td>Evidence of reaction type</td>
                    <td></td>
                    <td>Optional</td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Efficiently determine the behavior of the substance or
                      article or otherwise unambiguously interpret the results
                    </td>
                    <td></td>
                    <td>3 trials</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Crater at the test site</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Hole or depression in ground appreciably larger
                      <span style="color: a800000;">[subjective]</span> than
                      that from the single package test
                    </td>
                  </tr>
                  <tr>
                    <td>Damage to the witness plate</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Dent or perforation of the witness plate appreciably
                      greater
                      <span style="color: a800000;">[subjective]</span> than
                      that of the single package test
                    </td>
                  </tr>
                  <tr>
                    <td>Measurement of a blast</td>
                    <td>Overpressure probes</td>
                    <td>
                      Record of a shock wave (instantaneous rise in pressure)
                      significantly exceeding
                      <span style="color: a800000;">[subjective]</span> that of
                      a single package
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Violent disruption and scattering of most of the confining
                      material <span style="color: a800000;">[subjective]</span>
                    </td>
                    <td>
                      Observation of dispersion rate and visual post-test
                      inspection
                    </td>
                    <td>
                      Comparing observations against the definition of a mass
                      explosion hazard (one which affects almost the entire load
                      virtually instantaneously)
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">
                *Assessments are taken together to determine if a mass explosion
                event (HD 1.1) occurred.
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-3">
            <TitleComponent
              class="py-8"
              title="Example Photo of No Simultaneous Explosion of More Than One Package (-)"
              size="medium"
            />
            <a
              target="_blank"
              :href="require('../../assets/UN/nogo-stack-test-min.jpg')"
            >
              <img
                class="m-auto"
                style="width:80%;"
                src="@/assets/UN/nogo-stack-test-min.jpg"
              />
            </a>
            <TitleComponent
              class="py-8"
              title="Example Photo of Simultaneous Explosion of More Than One Package (+)"
              size="medium"
            />
            <a
              target="_blank"
              :href="require('../../assets/UN/go-stack-test-min.jpg')"
            >
              <img
                class="m-auto"
                style="width:80%;"
                src="@/assets/UN/go-stack-test-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pl-3">
            <TitleComponent
              class="py-8"
              title="Example Video of No Simultaneous Explosion of More Than One Package (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88934768?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88934768"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <TitleComponent
              class="py-8"
              title="Example Photo of Simultaneous Explosion of More Than One Package (+)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88934766?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88934766"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
      <UnSideBar class="w-full sm:w-1/5"></UnSideBar>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import UnSideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    UnSideBar
  }
};
</script>

<style lang="scss"></style>
